<template>

    <div>
         <b-row  class="fixed_header">
    
           
            <b-col  sm="12" md="12" lg="12" xl="12" align="center">
    
                <img style="width: 118px;" src="/img/eqcoopblanco.svg"/>
    
    
            </b-col>
               
        </b-row>
        <b-row style="margin-top:70px">
    
            <b-col sm="12" md="12" lg="3" xl="3">
    
            </b-col>
    
            <b-col sm="12" md="12" lg="6" xl="6" style="margin-top: 15px">
    
                <b-card style="padding: 15px;">
    
                   
                        <p style="font-size:25px;    margin-bottom: 25px;line-height: 30px;font-weight:bold;text-align:center">Instrucciones para la eliminación de datos de usuario en My Roomies</p>
                
        
    
                        <p style="font-size:16px;text-align:justify">
  
  Si desea eliminar sus datos personales de My Roomies, siga las siguientes instrucciones:<br>
  <br>
  1.- Inicie sesión en su cuenta de My Roomies utilizando sus credenciales de inicio de sesión.
  <br>
  2.- Una vez que haya iniciado sesión, vaya a la sección de "Configuración" o "Perfil" en su cuenta. Esto puede estar ubicado en la parte superior o en el menú desplegable, dependiendo del diseño del sitio web.
  <br>
  3.- Dentro de la sección de "Configuración" o "Perfil", busque la opción que se refiere a la "Privacidad" o "Datos personales".
  <br>
  4.- En la sección de privacidad o datos personales, debería encontrar una opción para "Eliminar cuenta" o "Eliminar datos". Haga clic en esa opción.
  <br>
  5.- Es posible que se le solicite ingresar su contraseña o proporcionar alguna información adicional para confirmar su solicitud de eliminación de datos. Siga las instrucciones y proporcione la información requerida.
  <br>
  6.- Revise cuidadosamente los detalles proporcionados y confirme su solicitud para eliminar su cuenta y datos personales de My Roomies.
  <br>
  7.- Dependiendo de las políticas y procesos de My Roomies, es posible que se le envíe un correo electrónico de confirmación para verificar su solicitud. Siga las instrucciones en el correo electrónico, si corresponde.
  <br>
  8.- Una vez que haya completado el proceso de eliminación de datos, se eliminarán todos los datos personales asociados con su cuenta de My Roomies de manera permanente. Tenga en cuenta que esta acción no se puede deshacer y no podrá recuperar sus datos después de que se hayan eliminado.
  <br>
  <br>
  Es importante tener en cuenta que, en algunos casos, My Roomies puede retener cierta información según lo requieran las leyes aplicables o para fines legales o comerciales legítimos.


  <br>
  <br>

  Si tiene alguna dificultad o pregunta sobre el proceso de eliminación de datos, le recomendamos que se comunique con el servicio de atención al cliente de My Roomies para obtener asistencia adicional.
  <br>

  <br>
  Recuerde que al eliminar su cuenta y datos personales, perderá acceso a todos los contenidos y servicios asociados con My Roomies, y no podrá recuperarlos en el futuro.


</p>
        
     
    
                </b-card>
    
    </b-col>
    
    <b-col sm="12" md="12" lg="3" xl="3">
    
    </b-col>
      
    
    
             
    
        </b-row>
    
        <b-row  class="fixed_footer">
    
            <b-col
                style="color:white !important;"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                class="text-center align-items-center justify-content-xl-between"
              >
       
            
    
                <a
                  target="_blank"
                  title="Facebook de EQCoop"
                  href="https://www.facebook.com/eqpay.oficial"
                >   <feather-icon
                 icon="FacebookIcon"
                 size="25"
                 class="redesWhite"
                 /></a>
                <a
                  target="_blank"
                  title="Instagram de EQCoop"
                  href="https://www.instagram.com/eqpay.oficial"
                > <feather-icon
                 icon="InstagramIcon"
                 size="25"
                 class="redesWhite"
                 /></a>
                <a
                  target="_blank"
                  title="Email de EQCoop"
                  href="mailto:hola@eqcoops.com?Subject=Tengo%20una%20pregunta"
                > <feather-icon
                 icon="MailIcon"
                 size="25"
                 class="redesWhite"
                 /></a>
                <a
                  target="_blank"
                  title="Tiktok de EQCoop"
                  href="https://www.tiktok.com/@eqpays?_t=8WRpV7M3v65&_r=1"
                > <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                  widht="10"
                  height="22"
                  viewBox="0 0 448 512"
                ><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" /></svg></a>
    
                <p style="margin-top: 10px; margin-bottom:0; font-size: 12px;">  <a
                    class="color_a"
                  href="#"
                  target="_blank"
                >Términos y condiciones</a> - <b-link
                class="color_a"
                  :to="{ name: 'privacidad'}"
                  target="_blank"
                >Política de privacidad y condiciones de servicios</b-link> -  <a
                class="color_a"
                  href="#"
                  target="_blank"
                >Reclamos</a></p>
    
                <p style="margin-top: 10px; margin-bottom:0; font-size: 12px;"> Copyright &copy; {{ year }} <a
                  href="https://web.eqcoops.com"
                  target="_blank"
                  class="color_a"
                >- EQCoop</a></p>
    
              </b-col>
    
    
        </b-row>
    
    </div>
      </template>
      
      <script>
      /* eslint-disable global-require */
      import { BRow, BCol, BButton, BCard, BLink } from 'bootstrap-vue'
    
      
      export default {
        components: {
            BRow, BCol, BButton, BCard, BLink
        },
        data() {
    
         
          return {
            year: new Date().getFullYear(),
          }
        },
        computed: {
    
        },mounted(){
    
    
         
            document.title = 'EQCoop - Eliminación de datos'
        },
        methods: {
    
    
        }
      }
      </script>
      
      <style lang="scss">
    
    
    
    
      </style>
      